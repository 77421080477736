  // Formularios y Cajitas
  .inv-listing-form .checkbox input  {
	width: auto;
 //  margin: 0 0 0 -20px;
	width: 1.3em;
	height: 1.3em;
}

.inv-listing-form .checkbox{
 margin-left: 10px;
}

.inv-listing-form .checkbox .label{
 margin-left: 10px;
 font-weight: bold;
}

.inv-listing-form {
 background-color: #fff;
 // max-width: 770px;
 width: 100%;
 border-radius: 50px;
 padding: 40px 30px 40px 30px;
 margin-left: auto;
 margin-right: auto; }
 .inv-listing-form form:after {
	content: '';
	display: table;
	clear: both; }
 .inv-listing-form form > button {
	margin-top: 40px;
	text-align: center;
	width: 100%;
	line-height: 60px;
	background-color: #e95c53;
	font-weight: 700;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 50px; }
 .inv-listing-form a {
	color: #e95c53;
	font-weight: 700;
	letter-spacing: 1px;
	-webkit-transition: all .3s ease;
	transition: all .3s ease; }
	.inv-listing-form a:hover {
	  color: #e95c1c; }
 .inv-listing-form p {
	font-size: .875rem;
	line-height: 27px; }
 .inv-listing-form label {
	float: left;
	width: 100%;
 //  display: block;
	margin-top: 6px;
	/* Firefox 19+ */
	/* Firefox 18- */ }
	.inv-listing-form label.margin-lg-t15 {
	  margin-top: 15px; }
	.inv-listing-form label .inv-input-date {
	  display: block;
	  position: relative; }
	.inv-listing-form label:after {
	  content: '';
	  display: table;
	  clear: both; }
	.inv-listing-form label.inv-half-width {
	  width: 48%;
	  margin-right: 4%;
	  box-sizing: border-box; }
	  .inv-listing-form label.inv-half-width input {
		 padding-left: 58px; }
	  .inv-listing-form label.inv-half-width:nth-child(2n) {
		 margin-right: 0; }
	.inv-listing-form .inv-input-file {
	  margin-top: 13px;
	  margin-bottom: 15px; }
	  .inv-listing-form .inv-input-file span {
		 width: 100%;
		 display: inline-block;
		 float: left; }
	  .inv-listing-form label.inv-input-file input {
		 display: inline;
		 position: absolute;
		 width: 0;
		 height: 0;
		 visibility: hidden;
		 opacity: 0;
		 padding: 0; }
	  .inv-listing-form label.inv-input-file b {
		 display: inline-block;
		 float: left; }
	  .inv-listing-form label.inv-input-file .inv-input-elem {
		 color: #999;
		 font-size: .875rem;
		 font-weight: 500;
		 width: 44%;
		 height: 60px;
		 background-color: #f0f0f0;
		 border: 2px solid #f0f0f0;
		 border-radius: 50px;
		 display: block;
		 padding: 20px 30px;
		 position: relative;
		 margin-bottom: 0; }
	.inv-listing-form input {
	  width: 100%;
	  color: #999;
	  font-size: .875rem;
	  font-weight: 500;
	  border: 2px solid #c8c8c8;
	  border-radius: 50px;
		 // Ojo Error en Firefox
	 // padding: 18px 25px;
	  padding-left: 25px;
	height: 40px;
	  box-sizing: border-box;
	  outline: none; }
	 .inv-listing-form textarea {
	  width: 100%;
	  resize: vertical;
	  min-height: 210px;
	  background-color: white;
	  border: 2px solid #e8e8e8;
	  border-radius: 35px;
	  padding: 20px 25px;
	  letter-spacing: -.51px;
	  outline: none;
	  margin-bottom: -5px;}
	.inv-listing-form ::-webkit-input-placeholder {
	  color: #999; }
	.inv-listing-form ::-moz-placeholder {
	  color: #999; }
	.inv-listing-form :-moz-placeholder {
	  color: #999; }
	.inv-listing-form :-ms-input-placeholder {
	  color: #999; }
	.inv-listing-form .bootstrap-select.btn-group .dropdown-toggle .filter-option {
	  color: #999; }
	.inv-listing-form .bootstrap-select.btn-group .dropdown-toggle .caret {
	  right: 30px; }
	.inv-listing-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	  width: 100%;
	  outline: none; }
	  .inv-listing-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) button {
		 background-color: transparent;
		 box-shadow: none;
		 outline: none;
		 color: #999;
		 font-size: .875rem;
		 font-weight: 500;
		 border: 2px solid #e8e8e8;
		 border-radius: 50px;
		 padding: 18px 25px;
		 box-sizing: border-box; }
		 .inv-listing-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) button span {
			margin-bottom: 0; }
 .inv-listing-form span {
	font-size: 1rem;
	font-weight: 500;
	display: inline-block;
//    margin-bottom: 5px;
	/* Text style for "Twitter URL" */
	color: #4d4d4d;
//   margin-bottom: 13px; 
}
 .inv-listing-form i {
	color: #b3b3b3; }
 
 
 
 // Adición Matías en Base a Template
 .inv-bg-block {
	position: relative;
	background-position: center; 
	background-size: cover; }
	.inv-bg-block:before {
	  position: absolute;
	  top: 0;
	  left: 0;
	  content: '';
	  width: 100%;
	  display: inline-block;
	  height: 100%;
		background-color: rgba(17, 27, 80, 0.52); 
	}
 .inv-start-block header {
	text-align: center; }
	.inv-start-block header h1 {
	  color: white;
	  font-size: 42px;
	  font-weight: 700;
	  line-height: 44px;
		letter-spacing: 0px;
    word-spacing: 10px;
	  text-transform: uppercase;
	  margin-bottom: 3px; }
	.inv-start-block header h5 {
	  color: white;
	  font-size: 28px;
	  font-weight: 300;
	  line-height: 30px;
	  letter-spacing: 1px;
	  word-spacing: .5px; }