@use '@angular/material' as mat;
@import './variables';

@include mat.core();

// Theme Bootstrap
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'third': $third,
  'fourth': $fourth,
  'fifth': $fifth,
  'light': $light,
  'danger': $danger,
  'action': $action,
  'isapre': $isapre,
  'seguro': $seguro,
);

$container-max-widths: (
  'sm': $max-widths-sm,
  'md': $max-widths-md,
  'lg': $max-widths-lg,
  'xl': $max-widths-xl,
  'xxl': $max-widths-xxl,
);

$grid-breakpoints: (
  'xs': $breakpoints-xs,
  'sm': $breakpoints-sm,
  'md': $breakpoints-md,
  'lg': $breakpoints-lg,
  'xl': $breakpoints-xl,
  'xxl': $breakpoints-xxl,
);

// Theme Material
$custom-typography: mat.define-typography-config($font-family: '"Inter"');

$mat-queplan: (
    50 : #fdecef,
    100 : #f9cfd8,
    200 : #f5b0be,
    300 : #f190a4,
    400 : $primary,
    500 : $primary,
    600 : $primary,
    700 : $primary,
    800 : $primary,
    900 : $primary,
    A100 : #ffffff,
    A200 : #fff0f2,
    A400 : #ffbdc6,
    A700 : #ffa3b0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$queplan-primary: mat.define-palette($mat-queplan, 500);
$queplan-accent: mat.define-palette($mat-queplan, 700);

// The warn palette is optional (defaults to red).
$queplan-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$queplan-theme: mat.define-light-theme((
  color: (
    primary: $queplan-primary,
    accent: $queplan-accent,
    warn: $queplan-warn
  ),
  typography: $custom-typography
));

$mat-primary: map-get($queplan-theme, primary);
$mat-accent: map-get($queplan-theme, accent);

@include mat.all-component-themes($queplan-theme);

.mat-button-toggle-checked {
  background-color: mat.get-color-from-palette($mat-primary);
  color: mat.get-color-from-palette($mat-primary, default-contrast);
}

.mat-button,
.mat-raised-button {
  &.mat-primary[disabled] {
    color: rgba(mat.get-color-from-palette($mat-primary, default-contrast), 1) !important;
    background-color: rgba(mat.get-color-from-palette($mat-primary), 0.5) !important;
  }

  &.mat-button-large {
    line-height: 3rem;
    font-size: 1.5rem;
  }
}

.mat-icon-button {
  .mat-button-wrapper {
    font-family: none;
  }
}
.search-medical-center>.mat-select-search-inner{
  background-color: white;
}
