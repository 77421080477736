@import './variables';
@import './fonts';

::ng-deep {

  /* Se agrega este bloque de css ya que el sitio sube 75px
  cuando el header se coloca en position fixed */

//  qp-suspense-spinner,
//  app-contacto,
//  app-compare,
//  app-planes,
//  app-home,
//  qp-blog,
//  qp-dynamic-landing,
//  qp-not-found,
//  qp-paid-media,
//  ih-home-landings{
//    @media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
//      margin-top: 75px!important;
//      display: block;
//    }
//  }

//  .app-planes {
//   @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
//     margin-top: 75px!important;
//     display: block;
//   }
//  }

  .mat-option.mat-active {
    border-left: 4px solid map-get($theme-colors, 'primary');
  }

  .mat-option-text,
  // .mat-mdc-form-field,
  .mat-mdc-floating-label,
  .mat-mdc-select-value,
  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .btn,
  .mat-radio-label-content {
    @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: .875rem;
    }
  }

  .mdc-text-field--outlined .mdc-floating-label,
  .mat-mdc-option {
    @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: .875rem!important;
    }
  }

  .mat-mdc-select-value, .mat-mdc-radio-button .mdc-form-field {
    color: map-get($theme-colors, 'text');
  }

  .mat-input-element {
    box-sizing: border-box !important;
  }

  .mat-select-panel {
    padding: 10px 12px !important;
    border-radius: 8px;
  }

  .mat-tab-group,
  .mat-form-field,
  .mat-select,
  .mat-radio-label-content,
  .mat-option-text  {
    font-family: 'Inter';
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: map-get($theme-colors, 'secondary');
  }
}

.spinner-gif {
  width: 100vw !important;
}

@media (min-width: 576px) {
  .spinner-gif {
    width: 75vw !important;
  }
}

@media (min-width: 992px) {
  .spinner-gif {
    width: 63vw !important;
  }
}

@media (min-width: 1200px) {
  .spinner-gif {
    width: 54vw !important;
  }
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

p, .mat-select-value{
  font-size: 1rem;
  color: map-get($theme-colors, 'text');
  line-height: 1.5;

  @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: .875rem;
  }
}

.alert-strong {
  font-size: .75rem;
}

.text-black{
  color: map-get($theme-colors, 'text');
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  width: 5%;
}

@mixin animated-skeleton() {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #eff1f6;
  background: linear-gradient(to right, #eeeeee 8%, #d9d9d9 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

@mixin scroll-custom() {
  ::-webkit-scrollbar {
    background-color: #f1f1f1;
    border-radius: 100px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    height: 18px;
    background-color: #c5e9ff;
    border-radius: 100px;
  }
}

@mixin Title() {
  font-weight: 500;
  margin-bottom: .5rem;

  b {
    font-weight: 600;
    color: map-get($theme-colors, 'primary');
  }
}


.qp-title {
  @include Title();
  font-size: 1.5rem;
  color: map-get($theme-colors, 'text');

  @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 2rem;
  }
}

.qp-bg-title {
  @include Title();
  font-size: 1.5rem;
  color: map-get($theme-colors, 'text');

  @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 2rem;
  }
}

.qp-subtitle {
  color: #64748b;
  font-size: 1.1rem;
  line-height: 1.3;
  max-width: 800px;

  // @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  //   font-size: 1rem;
  // }
}

.qp-subtitle-italic {
  font-style: italic;
  line-height: 1.5;

  @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 1rem;
  }
}

.qp-btn-primary {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    background: linear-gradient(120deg, #0ea5e9, #3b82f6);
    color: white!important;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    border: none;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(14, 165, 233, 0.2);
    }

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      width: 100%;
      justify-content: center;
    }
}

.qp-btn-secondary {
  color: white;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1rem;
  width: fit-content;
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid white;
  display: inline-block;

  @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: .875rem;
  }

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.192);
  }
}

.qp-section {
  margin: 8rem 0;
  @media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    margin: 5rem 0;
  }
}

.qp-box-shadow {
  border-radius: 10px;
  border: 1px solid #ededed;
  box-shadow: 0px 15px 20px -15px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 15px 20px -15px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 15px 20px -15px rgba(0,0,0,0.1);
}

.qp-box-hover-animation {
  cursor: pointer;
  transition: 0.3s;

  &:hover,
  &.active {
    background: #eff3ff;
    color: map-get($theme-colors, 'third');
    box-shadow: 0px 5px 10px rgb(175 182 229 / 30%);
  }
}

.btn-action {
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 5px 30px;
  border-radius: 4px;
  min-width: 200px;
  height: 48px;

  .icon-arrow {
    padding-right: 12px;
    font-size: 2rem
  }
}

.text-over{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 250px;
  }

.footer-solicitud {
  bottom: 0;
  width: 100%;
  padding: 14px 0;
  position: sticky;
  z-index: 999;
  background: white;
  margin-bottom: -20px;
  border-top: 3px solid;

  .border-color {
    border: none;
    border-top: 5px solid;
  }

  .btn-action {
    padding-top: 0;
    padding-bottom: 0;

    >div {
      flex-direction: row !important;
    }
  }
}

.fade-in {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-right {
  -webkit-animation: fade-in-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-left {
  -webkit-animation: fade-in-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.opacity-1,
.visible {
  visibility: visible;
  opacity: 1;
}

.underline {
  text-decoration: underline !important;
}

.disabled-a {
  pointer-events: none;
  opacity: 0.4;
}

.mat-mdc-form-field-infix {
  // padding-top: 16px !important;
  padding-bottom: 16px !important;
}

// .custom-price {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// @media only screen and (max-width: 768px) {
//   .other-benefits-content {
//     flex-direction: column;
//     align-items: center;
//   }
// }

.text-contact {

  p {
    line-height: 1.25rem;
    letter-spacing: .2px;
  }

  a {
    font-weight: 500;
  }

  ul {
    padding-left: 2rem;

    li {
      list-style: none;
      padding-bottom: .6rem;
    }

    li::before {
      content: "\2022";
      color: var(--color-primary);
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

.container-1140{
  max-width: 1140px;
  margin: 0 auto;
}

.f-14{
  font-size: .875rem
}

.modal-timeline-interaction {
  .modal-content {
    overflow-y: scroll;
    max-height: 93vh;
    min-height: 45vh;
  }
}

.mdc-tab-indicator--active{
  border: 0!important;
}

.new-beneficiary {
  border: solid 1px #eeecec;
  padding: 3.9px 9px;
  border-radius: 3.6px;

  label {
    color: #646262;
    font-size: 0.75rem;
    margin-bottom: 1.5px;
  }
}