
  /*--------------------------------------------------------*/
  /* Home inv-places-item */
  /*--------------------------------------------------------*/
  .inv-block-header {
	text-align: center; }
	.inv-block-header h3 {
	  color: #485160;
	  font-size: 30px;
	  font-weight: 700;
	  letter-spacing: 1px;
	  margin-bottom: 5px;
	  line-height: 44px;
	  text-transform: uppercase; }
	.inv-block-header span {
	  color: #8c8c8c;
	  font-size: 1rem;
	  font-weight: 400;
	  line-height: 18px;
	  letter-spacing: 1.7px;
	  display: inline-block;
	  position: relative; }
 
 .inv-places2 .inv-block-header.dark h3 {
	color: #4d4d4d; }
 
 .inv-places2 .inv-block-header.dark span {
	color: #4d4d4d; }
 
 .inv-places:after {
	content: '';
	display: table;
	clear: both; }
 
 .inv-places-item {
	position: relative;
	background-size: cover;
	background-position: center;
	border-radius: 50px;
	background-repeat: no-repeat;
	float: left;
	margin-right: 20px;
	max-height: 270px;
	width: 100%;
	margin-bottom: 20px; }
	.inv-places-item img {
	  border-radius: 50px; }
	.inv-places-item:nth-child(3n) {
	  margin-right: 0; }
	.inv-places-item:before {
	  content: '';
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  border-radius: 50px;
	  -webkit-transition: all 0.3s ease;
	  transition: all 0.3s ease; }
	.inv-places-item.bg1:hover:before {
	  background-color: rgba(194, 115, 230, 0.5); }
	.inv-places-item.bg2:hover:before {
	  background-color: rgba(118, 191, 247, 0.5); }
	.inv-places-item.bg3:hover:before {
	  background-color: rgba(122, 219, 140, 0.5); }
	.inv-places-item.bg4:hover:before {
	  background-color: rgba(240, 195, 82, 0.5); }
	.inv-places-item.bg5:hover:before {
	  background-color: rgba(235, 96, 125, 0.5); }
	.inv-places-item.bg6:hover:before {
	  background-color: rgba(95, 215, 237, 0.5); }
 
 .inv-places-info {
	width: 100%;
	padding-left: 20px;
	position: absolute;
	bottom: 20px; }
	.inv-places-info i {
	  display: inline-block;
	  border-radius: 50%;
	  text-align: center;
	  width: 60px;
	  height: 60px;
	  color: #fff;
	  font-size: 20px;
	  margin-right: 15px; }
	  .inv-places-info i:before {
		 line-height: 60px; }
	.inv-places-info span {
	  color: white;
	  font-size: 1rem;
	  font-weight: 600;
	  line-height: 62px;
	  text-transform: uppercase; }
 
 .add-space {
	float: left;
	width: 100%; }
 
 @media only screen and (max-width: 767px) {
	.add-space {
	  float: none; }
	.inv-listing-result .inv-places2-item {
	  box-shadow: none; } }