  @font-face {
	font-family: 'QuePlan';
	font-style: normal;
	font-weight: 300;
	src: local('QuePlan light'), local('QuePlan-light'), url(https://cdn.queplan.cl/shared/fonts/QuePlanLight.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

  @font-face {
	font-family: 'QuePlan';
	font-style: normal;
	font-weight: 700;
	src: local('QuePlan Medium'), local('QuePlan-Medium'), url(https://cdn.queplan.cl/shared/fonts/QuePlanMedium.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

  @font-face {
	font-family: 'QuePlan';
	font-style: normal;
	font-weight: 800;
	src: local('QuePlan Bold'), local('QuePlan-Bold'), url(https://cdn.queplan.cl/shared/fonts/QuePlanBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

  @font-face {
	font-family: 'QuePlan';
	font-style: normal;
	font-weight: 900;
	src: local('QuePlan Black'), local('QuePlan-Black'), url(https://cdn.queplan.cl/shared/fonts/QuePlanExtraBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }

// Fuente Poppins para todos los Header

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local('Poppins Regular'), local('Poppins-Regular'), url(https://cdn.queplan.cl/shared/fonts/PoppinsRegular.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: swap;
  }

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local('Poppins Medium'), local('Poppins-Medium'), url(https://cdn.queplan.cl/shared/fonts/PoppinsMedium.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://cdn.queplan.cl/shared/fonts/PoppinsSemiBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local('Poppins Bold'), local('Poppins-Bold'), url(https://cdn.queplan.cl/shared/fonts/PoppinsBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }
