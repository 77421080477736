$primary: #40b4e5;
$secondary: #808080;
$third: #0e6fc5;
$fourth: #ff0038;
$fifth: #f5fafd;

$text: #1d1d1f;
$white: #ffffff;
$light: #f1f1f1;

$bgLightblue01: #ECF9FF;
$bglightblue02: #9AE1FF;
$bggrey: #F6F6F6;

$success: #00B900;
$error: #FF4C7D;
$danger: #d30b40;
$action: #d30b40;

$isapre: #ff0038;
$seguro: #00a4b2;

$seguroMascota: #40b4e5;
$seguroSalud: #00a4b2;
$seguroEmpresa: #1a92c5;
$seguroIsapre: #ff0038;
$seguroVida: #7C3E83;

$max-widths-sm: 540px;
$max-widths-md: 720px;
$max-widths-lg: 960px;
$max-widths-xl: 1170px;
$max-widths-xxl: 1320px;

$breakpoints-xs: 0;
$breakpoints-sm: 576px;
$breakpoints-md: 768px;
$breakpoints-lg: 992px;
$breakpoints-xl: 1200px;
$breakpoints-xxl: 1400px;

:root {
    --color-primary: #{$primary};
    --color-secondary: #{$secondary};
    --color-third: #{$third};
    --color-fourth: #{$fourth};
    --color-fifth: #{$fifth};
    --color-light: #{$light};
    --color-danger: #{$danger};
    --color-action: #{$action};

    --color-text: #{$text};
    --color-white: #{$white};

    --color-bgLightblue01: #{$bgLightblue01};
    --color-bglightblue02: #{$bglightblue02};
    --color-bggrey: #{$bggrey};

    --color-success: #{$success};
    --color-error: #{$error};

    --color-isapre: #{$isapre};
    --color-seguro: #{$seguro};

    --color-seguroMascota: #{$seguroMascota};
    --color-seguroSalud: #{$seguroSalud};
    --color-seguroEmpresa: #{$seguroEmpresa};
    --color-seguroIsapre: #{$seguroIsapre};
    --color-seguroVida: #{$seguroVida};

    --max-widths-sm: #{$max-widths-sm};
    --max-widths-md: #{$max-widths-md};
    --max-widths-lg: #{$max-widths-lg};
    --max-widths-xl: #{$max-widths-xl};
    --max-widths-xxl: #{$max-widths-xxl};

    --breakpoints-xs: #{$breakpoints-xs};
    --breakpoints-sm: #{$breakpoints-sm};
    --breakpoints-md: #{$breakpoints-md};
    --breakpoints-lg: #{$breakpoints-lg};
    --breakpoints-xl: #{$breakpoints-xl};
    --breakpoints-xxl: #{$breakpoints-xxl};
    --end-banner-gradient-lg: 80%;
    --card-btn-insurance-background-color: #ff0046;
    --detail-btn-insurance-background-color: #40b4e5;
    --card-btn-insurance-hover: #e2003c;
    --profile-form-background: #FFFFFF;
    --profile-form-text: #1d1d1f;
}
