@media screen {
  .modal-xxl .modal-lg {
    margin: 1.25rem auto;
    min-width: 90%;
    max-width: 92%;
  }

  .modal-comparator {
    .modal-dialog {
      min-width: 70%;
      margin: 1.25rem auto;
    }
  }

  .modal-sm-custom .modal-lg {
    min-width: 48%;
    max-width: 48%;
  }

  .modal-asignada-custom .modal-lg {
    min-width: 35%;
    max-width: 35%;
  }
}

.colorheader {
  background-color: rgba(255, 255, 255, 0.92);
}

.colorfooter {
  background-color: rgba(153, 156, 175, 0.43);
}
