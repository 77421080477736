/* @import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";


 */
// Inventory Core
@import "background_colors.scss";
@import "global.scss";
@import "formularios.scss";
@import "home.scss";
@import "custom_global.scss";
@import "modal.scss";

// @import 'ngx-bar-rating/themes/br-default-theme';
