.mdc-text-field,
.mdc-text-field--focused,
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay,
.mat-form-field-appearance-fill,
.mat-form-field-flex  
{
  background-color: rgba(255, 255, 255, 0) !important;
}
.mat-mdc-form-field{
  display: inline !important;
}