// Info de filtros
.mat-tooltip {
  font-size: .75rem !important;
}

.despliegue-prestadores {
  width: 15.3rem !important;
}
// en caso de necesitar este estilo en algun lado aplicar localmente o rompera estilos en varios lados.
/*.cdk-overlay-pane {
  max-width: none !important;
  width: auto !important;
}*/

// Botones
button {
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

// Boton Global
.inv-btn {
  color: white;
  font-size: .875rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #e95c53;
  line-height: 60px;
  padding: 0 65px;
  letter-spacing: 1.3px;
  display: inline-block;
  border-radius: 50px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.inv-btn:hover {
  background-color: #4d4d4d;
}

// Puntero
.pointer {
  cursor: pointer;
}

ngb-accordion.filtros {
  .card {
    margin-bottom: 10px;
  }
}

.errorPanel {
  .card-header {
    margin-bottom: 0;
    color: white !important;
    background-color: #dc3545 !important;
  }

  .card-header a {
    color: white !important;
  }

  .card-body {
    border: 1px solid #dc3545 !important;
    color: #dc3545 !important;
    font-weight: bold !important;
  }
}

.warningPanel:not(.ignore) {
  .card-header {
    margin-bottom: 0;
    color: #856404 !important;
    background-color: #ffdf7b !important;
  }

  .card-header a {
    color: white !important;
  }

  .card-body {
    border: 1px solid #ffdf7b !important;
  }
}

.pocosResultados:not(.ignore) {
  .card-header {
    margin-bottom: 0;
    color: #856404 !important;
    background-color: #ffdf7b !important;
  }

  .card-header a {
    color: white !important;
  }

  .card-body {
    border: 1px solid #ffdf7b !important;
  }
}

.filtros-box {
  box-sizing: border-box;
  border: 1px solid #DDD;
  display: inline-block;
  text-align: center;
  line-height: 23px;
  font-size: .75rem;
  height: 25px;
  width: 25px;
  font-weight: bold;
}
