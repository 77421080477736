  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local('Poppins Regular'), local('Poppins-Regular'), url(https://cdn.queplan.cl/shared/fonts/PoppinsRegular.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: swap;
  }

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local('Poppins Medium'), local('Poppins-Medium'), url(https://cdn.queplan.cl/shared/fonts/PoppinsMedium.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://cdn.queplan.cl/shared/fonts/PoppinsSemiBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }

  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local('Poppins Bold'), local('Poppins-Bold'), url(https://cdn.queplan.cl/shared/fonts/PoppinsBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  }

	@font-face {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 300;
		src: local('Inter light'), local('Inter-light'), url(https://cdn.queplan.cl/shared/fonts/InterLight.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		font-display: swap;
	}
	
	@font-face {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		src: local('Inter Regular'), local('Inter-Regular'), url(https://cdn.queplan.cl/shared/fonts/InterRegular.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		font-display: swap;
		}
	
		@font-face {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		src: local('Inter Medium'), local('Inter-Medium'), url(https://cdn.queplan.cl/shared/fonts/InterMedium.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		font-display: swap;
	}
	
		@font-face {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		src: local('Inter Bold'), local('Inter-Bold'), url(https://cdn.queplan.cl/shared/fonts/InterBold.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		font-display: swap;
	}