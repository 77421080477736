$theme-colors: (
  'primary': var(--color-primary, #40B4E5),
  'secondary': var(--color-secondary, #808080),
  'third': var(--color-third, #0E6FC5),

  // FOURTH Y FIFTH NO SETEADOS AÚN
  //'fourth': var(--color-fourth, #ff0046),
  //'fifth': var(--color-fifth, #f5fafd),

  'text': var(--color-text, #1d1d1f),
  'white': var(--color-white, #ffffff),
  'light': var(--color-light, #f1f1f1),
  'input': var(--color-input, #9a9a9a),

  'success': var(--color-success, #00B900),
  'error': var(--color-error, #FF4C7D),
  'danger': var(--color-danger, #d30b40),
  'action': var(--color-action, #d30b40),
  'star': var(--color-star, #fa8e04),

  'bgLightblue01': var(--color-bglightblue01, #ECF9FF),
  'bglightblue02': var(--color-bglightblue02, #9AE1FF),
  'bggrey': var(--color-bggrey, #F6F6F6),
  'border-grey': var(--color-border-grey, #C6CACE),

  //SEGUROS
  'seguroMascota': var(--color-seguroMascota, #40b4e5),
  'seguroSalud': var(--color-seguroSalud, #00a4b2),
  'seguroEmpresa': var(--color-seguroEmpresa, #1a92c5),
  'seguroIsapre': var(--color-seguroIsapre, #e61951),
  'seguroVida': var(--color-seguroVida, #7C3E83),

  'isapre': #e61951,
  'seguro': #00a4b2,
);

$container-max-widths: (
  'sm': 540px,
  'md': 720px,
  'lg': 960px,
  'xl': 1170px,
  // se copia lo que viene en bootstrap 5 https://getbootstrap.com/docs/5.0/layout/containers/#sass
  'xxl': 1320px,
);

// TODO fix var breakpoints
/**
* Actualmente los vars breakpoints no estan funcionando, siempre toma el valor por defecto.
* Se debe buscar una forma de hacerlo funcionar
*
* Nota: si se setea un valor fijo funciona, sin embargo la idea es que tome la variable del proyecto
*/
$grid-breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  // se copia lo que viene en bootstrap 5 https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
  'xxl': 1400px,
);
