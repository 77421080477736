/* You can add global styles to this file, and also import other style files */
@use 'sass:math';

@import './variables';
@import './fonts';
@import './theme.scss';
@import 'bootstrap/scss/bootstrap';

// Mixin efecto Pulse
@mixin btn-solicitud {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  opacity: 0;
  margin: auto;
}

html:focus-within {
  height: 100vh;
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
}

body {
  font-family: 'Inter';
  font-weight: 400;
  background: #fcfdff;
  color: map-get($theme-colors, 'text');
  padding: 0 !important;

  &.sidebar-open {
    overflow: hidden;
  }

  &.modal-open {
    .cdk-overlay-container {
      z-index: 1051 !important;
    }
  }

  .cdk-overlay-container {
    z-index: 998 !important;
  }

}

// Luego iran al Global

h1,
h2,
h3,
h4 {
  font-family: Poppins, sans-serif;
  font-weight: 500;
}

button,
.btn {
  /* border-radius: 5px !important; */

  &:focus {
    outline: 0;
  }

  &.btn-outline-primary {
    &:hover {
      color: #ffffff !important;
    }
  }

  i.material-icons {
    vertical-align: middle;
    line-height: 0;
  }
}

.modal-transform-none {
  .modal-dialog {
    transform: none !important;
    -webkit-transform: none !important;
  }
}

.btn-pulse {
  position: relative;

  &:before {
    @include btn-solicitud;
    animation: pulse 1.5s infinite linear;
  }

  &:after {
    @include btn-solicitud;
    animation: pulse 2s 0.4s infinite linear;
  }
}

// Pulse
@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  33% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.btn-solicitar {
  color: white;
  padding: 10px 0px 6px 0px;
  line-height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  transition: 0.5s;
  border-bottom: 5px solid rgba(0, 0, 0, 0.19);

  &:hover {
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0 4px 16px rgba(49, 138, 172, 1);
  }

  fa-icon {
    font-size: 35px;
    margin-right: 10px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  height: 64px;
  width: 100%;
  text-align: center;

  div {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid map-get($theme-colors, 'primary');
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: map-get($theme-colors, 'primary') transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.help {
  cursor: help;
}

.op0 {
  opacity: 0;
}

.op1 {
  opacity: 1;
}

.space {
  padding: 0 1.5rem 0 1.5rem;

  @media only screen and (max-width: 700px) {
    padding: 0 0.3rem 0 0.3rem;
  }
}

.Spinners {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

$spinnerSize: 8;
$spinnerColPrimary: #40b4e5;
$spinnerColSecondary: #cfd8dc;

.Spinner {
  position: relative;
  display: block;
  font-size: 10px;
  width: $spinnerSize * 1em;
  height: $spinnerSize * 1em;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
  }
}

$spinnerRadarOuterSize: 0.15;
$spinnerRadarInnerSize: 0.4;
$spinnerRadarInnerTop: 0.5 - math.div($spinnerRadarInnerSize, 2);
$spinnerRadarInnerLeft: $spinnerRadarInnerTop;

.Spinner--radar {
  animation: radar 1200ms cubic-bezier(0.645, 0.045, 0.355, 1) infinite;

  &::before {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: $spinnerSize * $spinnerRadarOuterSize * 1em;
    border-color: $spinnerColSecondary transparent;
    border-radius: 50%;
  }

  &::after {
    top: $spinnerSize * $spinnerRadarInnerTop * 1em;
    left: $spinnerSize * $spinnerRadarInnerTop * 1em;
    width: $spinnerSize * $spinnerRadarInnerSize * 1em;
    height: $spinnerSize * $spinnerRadarInnerSize * 1em;
    background-color: $spinnerColPrimary;
    border-radius: 50%;
  }
}

@keyframes radar {
  0% {
    transform: translate3d(0, 0, 0) rotate(0);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(360deg);
  }
}

hr.stripes {
  width: 100%;
  height: 10px;
  background-image: url('https://cdn.queplan.cl/images/elementos/stripe-overlay.png');
  background-repeat: repeat;
  opacity: 0.3;
  border: none;
  margin: 10px 0px 0px 0px;
}

.rotationInfinite {
  animation: rotationInfinite 10s infinite linear;
}

@keyframes rotationInfinite {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.a-btn-verDetalle {
  padding: 0 !important;

  span {
    display: inline-block;
    height: inherit;
    width: inherit;
    padding: 10px 0 10px 0;
  }
}

.missingTranslation {
  position: relative;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: rgba(255, 165, 0, 0.5);
}
.missingTranslation::after {
  position: absolute;
  top: -4px;
  content: '\26A0'; // 26A0 : Simbolo de warning
  font-size: 0.9rem;
  color: orange;
}

.-qp55ff79a-h,
.info-qp55ff79a-insurance .-qpqprndstr-h,
[class$='-h'],
[class*='-h '] {
  display: none !important;
}

// Estilos para el webChat de Twilio

.Twilio-MainHeader-default {
  justify-content: space-evenly;
}

.Twilio-MainHeader-default div {
  font-size: 1rem !important;
}

.Twilio-EntryPoint,
.Twilio-MainContainer {
  z-index: 1200 !important;
}

.Twilio-MainHeader-default img {
  width: 1.5rem !important;
}

.Twilio-DynamicfORM div {
  margin-top: 12px !important;
}

.Twilio-DynamicForm div div {
  width: 100% !important;
}

// Estilos para los input´s del chat twilio (cod. área)
.Twilio-DynamicForm {
  display: inline-block !important;
}


.Twilio-DynamicForm :nth-child(3) {
  display: inline-block;
  width: 30%;
  margin-right: 0.5rem;
  vertical-align: top;
}

.Twilio-DynamicForm :nth-child(4) {
  display: inline-block;
  width: 67%;
  vertical-align: top;
}

.qp-iframe {
  app-header,
  app-header *,
  app-footer qp-footer {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
    width: 0 !important;
  }

  #twilio-customer-frame {
    display: none !important;
  }
}

.price-box-mobile {
  background-color: #00a4b2;
  color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin: 1.5rem 0px;
}

.poliza-text-mobile {
  margin: 0.5rem 0px;
  color: #00a4b2;
}
ngb-modal-backdrop {
  z-index: 1040 !important;
}
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
