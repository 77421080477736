

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block; }

  // body {
  //   line-height: 1; }

  ol, ul {
    list-style: none; }

  blockquote, q {
    quotes: none; }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none; }

  table {
    border-collapse: collapse;
    border-spacing: 0; }

  /*---------------------------------------------------------------------------------------*/
  /* Global settings */
  /*--------------------------------------------------------------------------------------*/
  @media only screen and (min-width: 768px) {
    .padd-only-xs {
      padding-left: 0;
      padding-right: 0; } }

  @media only screen and (min-width: 1200px) {
    .no-padd-lg {
      padding-left: 0;
      padding-right: 0; } }

  @media only screen and (max-width: 767px) {
    .add-padd-xs {
      padding-left: 15px;
      padding-right: 15px; } }

  a {
    text-decoration: none; }

  a:hover, a:active, a:focus, a:visited {
    text-decoration: none; }

  a:active, a:focus {
    outline: none; }

  .over-hide {
    overflow: hidden !important; }

  /* extends */
  .container-fluid {
    min-width: 280px; }

  .container {
    min-width: 280px; }


  /* background image settings */
  .wpc-back-bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

  /* eq columns 1 */
  .eq-row {
    overflow: hidden; }

  .eq-col {
    margin-bottom: -99999px;
    padding-bottom: 99999px; }

  /* eq columns 2 */
  .a-col-half {
    width: 50%;
    float: left; }

  .padd-lr0 {
    padding-left: 0;
    padding-right: 0; }

  .btn-filter{
		background-color: #0e6fc5;
		border: 1px solid #0e6fc5;
		color: #FFF;
			&:hover{
				background-color: #115796;
				border: 1px solid #115796;
				color: white;
			 }
  }

  .btn-blue{
    background-color: #0e6fc5;
		border: 1px solid #0e6fc5;
		color: #FFF;
			&:hover{
				background-color: #FFF;
				border: 1px solid #0e6fc5;
				color: #0e6fc5;
			 }
  }

  .btn-close{
    background-color: #4f7ea8;
		border: 1px solid #4f7ea8;
		color: #FFF;
			&:hover{
				background-color: #42698b;
				border: 1px solid #42698b;
				color: white;
			 }
  }

  .title-layout{
    font-size: .875rem;
    line-height: 1.5;
    color: #485160 !important;
  }

  @media only screen and (max-width: 500px) {
    .title-layout{
      font-size: .75rem;
      line-height: 1.5;
      color: #485160 !important;
    }
 }

  @mixin btn-solicitar ($background){
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 0 auto;
    background: $background;
    font-weight: 700 !important;
    padding: 5px 30px !important;
    @media only screen and (max-width:550px){
      width: 90%
    }
  }

  @mixin btn-footer ($btn-color){
    bottom: 0;
    width: 100%;
    padding: 14px 0;
    position: sticky;
    background: white;
    margin-bottom: -20px;
    border-top: 3px solid $btn-color;
    button{
      border: none;
      color: white;
      border-radius: 7px;
      padding: 15px 200px;
      background: $btn-color;
      transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55), background-position 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55), box-shadow 500ms linear;
      &:hover{
        transform: scale(1.03);
        background-position: -40px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }

  .p-top{
    padding-top: 77px;
  }

  // fadeIn animation
  .fadeIn {
    -webkit-animation-duration: 0.69s;
    animation-duration: 0.69s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeIn;
  }
  @keyframes fadeIn {
    from {
       opacity: 0;
    }
    to {
       opacity: 1;
    }
  } 
